import React from "react";
import styled from "styled-components";
import loadable from "@loadable/component"
import { graphql } from "gatsby";
import "../styles/globals.css";
// import { Navbar, Footer } from "../components";
import {
  Title,
  Section,
  Divider,
  SectionWrapper,
  HeaderBG,
} from "../components/core";

const Footer = loadable(() => import("../components"), {
  resolveComponent: (components) => components.Footer,
});
const Navbar = loadable(() => import("../components"), {
  resolveComponent: (components) => components.Navbar,
});

const RefundPolicy = () => {
  return (
    <>
      <Navbar />
      <div
        style={{
          marginTop: "80px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Section background="#ffffff">
          <HeaderBG />
          <SectionWrapper>
            <Left>
              <Title fontSize="48px">Refund Policy</Title>
            </Left>
          </SectionWrapper>
          <Section background="#fff">
            <SectionWrapper px="25px">
              <Left>
                <SpaceContainer>
                  (a). Free look period Cancellation and Refund
                  <br />
                  As per the IRDAI rules and regulations, you have the right to
                  cancel your insurance policy within 15(fifteen) days from the
                  date of (online) receipt of insurance policy (referred to as
                  “Free look period”) and refund of your premium amount shall be
                  processed as per the applicable process and procedures
                  followed by the insurer. This Free look facility can be
                  availed only for Life and Health insurance policies, subject
                  to certain other terms and conditions specified by IRDAI. We
                  encourage all our customers to read the insurance policy
                  document carefully and avail the free look facility in case
                  the terms and conditions of the insurance policy document
                  don’t match your insurance requirements. Further, you
                  understand that once you place a cancellation request within
                  the Free look period, the policy gets cancelled and entire
                  premium is refunded to you subject to deduction of (i) charges
                  pertaining to medical tests conducted (ii) administrative and
                  service cost like stamp duty etc. and; (iii )Charges for
                  mortality for the period the policy was in force. Please note
                  such deduction is at the sole discretion of the insurer.
                  <br />
                  <br />
                  (b) Chargebacks
                  <br />
                  You (cardholder) may dispute a transaction carried out through
                  our Website and file a Chargeback with your issuing bank for
                  the reversal of that transaction. Chargeback allows
                  transactions to be reversed by you under specified
                  circumstances such as, on account of (i) alleged forgery of
                  the card number / bank account or other details (ii) any
                  charge/debit made on a card that has been listed as a hot
                  listed card or otherwise listed on the card association (Visa,
                  MasterCard, etc.) warning bulletins (iii) duplicate processing
                  of the transaction; or (iv) for other reasons as per
                  applicable rules and guidelines issued by RBI, card
                  Associations, your card/payment instrument issuing bank etc.
                  However, we reserve the right to reject a Chargeback,
                  including but not limited to, under the following
                  circumstances:
                  <br />
                  <br />
                  <ol>
                    <li>
                      In the event your underwriting is in process with your
                      chosen insurer, not exceeding a period of 3 months from
                      the date of your insurance policy application
                    </li>
                  </ol>
                  <br />
                  Our Company shall not be liable to you, in any form or manner
                  whatsoever, for any claims, disputes, proceedings which may
                  arise in connection with Chargebacks initiated by you in the
                  circumstances as specified under (a), (b) and (c) herein
                  above.
                </SpaceContainer>
              </Left>
            </SectionWrapper>
          </Section>
        </Section>
        <Divider />
        <Footer />
      </div>
    </>
  );
};

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {
        ns: {
          in: ["index", "common", "navbar", "footer", "getQuoteModal"]
        }, 
        language: {eq: $language 
      }
    }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const Left = styled.div`
  text-align: left !important;
`;

const SpaceContainer = styled.div`
  margin-top: ${({ mt }) => `${mt ? mt + "px" : "0px"}`};
  margin-bottom: ${({ mb }) => `${mb ? mb + "px" : "0px"}`};
  margin-left: ${({ ml }) => `${ml ? ml + "px" : "0px"}`};
  margin-right: ${({ mr }) => `${mr ? mr + "px" : "0px"}`};

  font-size: 18px;
  color: #000000;
  line-height: 32px;

  li {
    margin-left: 20px;
  }

  a {
    font-weight: bold;
    color: blue;
  }
`;

export default RefundPolicy
